import Search from "../Search";
import './SearchTab.css'

function SearchTab() {
    return (
        <div className='searchTab'>
            <Search />
        </div>
    );
  }
  
  export default SearchTab;
  